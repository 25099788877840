import { datadogRum } from '@datadog/browser-rum';
import { Analytics } from '@melio/platform-analytics';
import { featureFlags } from '@melio/shared-web';

import { decodeAccessToken } from '@/utils/getAccessTokenData.utils';

type IdentifyParams = {
  identify: Analytics['identify'];
  partnerName: string;
  environmentName: string;
  appAccessToken?: string;
};

let wasIdentificationDoneReported = false;

export const identifyFeatureFlags = async ({
  identify,
  partnerName,
  appAccessToken,
  environmentName,
}: IdentifyParams) => {
  if (!appAccessToken) {
    return;
  }

  const { user, organization, partnerName: accessTokenPartnerName } = decodeAccessToken(appAccessToken);
  if (accessTokenPartnerName !== partnerName) {
    console.log('partnerName on access token not match with partnerName from url - not identifying');
    return;
  } else if (!user?.id || !organization?.id) {
    console.log('failed to load feature-flags - no user/org ids');
    return;
  }

  const channelName = (accessTokenPartnerName ?? partnerName)!;
  const userLike: featureFlags.User = { id: user.id, createdAt: user.createdAt, email: user.email };
  const orgLike: featureFlags.Organization = {
    id: organization.id,
    companyType: organization.companyType,
  };

  await featureFlags.defaultClient.identify(userLike, orgLike, channelName, environmentName);
  const { duration } = performance?.measure?.('LD_Measure', 'LD_mark') || {};
  if (!wasIdentificationDoneReported) {
    datadogRum.addTiming('feature_flags_identification_done', duration);
    wasIdentificationDoneReported = true;
  }
  identify({
    company: organization?.companyName,
    createOrigin: organization?.createOrigin,
    siteConfig: partnerName,
    userId: user.id,
    email: user.email,
    orgId: Number(organization?.id),
    registrationFlow: user.registrationFlow,
    organization: organization?.companyName,
  });
};
