export enum FeatureFlags {
  MultiOrgUI = 'platform-multi-org-ui',
  AccountManagerAccessCollaborators = 'am-access-collaborators-list',
  BatchPayments = 'channels-batch-payments',
  CombinedPayments = 'channels-combined-payments',
  JustPay = 'channels-enable-just-pay',
  VaultingTokenProvider = 'vaulting-token-provider',
  ExportPaymentsMultipleFormats = 'exoport-payments-multiple-formats',
  USHolidaysChecks = 'us-holidays-checks-partners',
  DashboardEmptyState = 'platform-dashboard-empty-state',
  NotificationPreferences = 'platform-notification-preferences',
  FeedbackWidget = 'feedback-widget-platform',
  RecurringPayments = 'recurring-payments-platform',
  FailedToCollect = 'platform-fail-to-collect',
  FailedToDeliverACH = 'platform-failed-to-deliver-ach',
  SyncNow = 'platform-sync-now',
  IsInternationalDeliveryMethodSupported = 'platform-international-payments-web',
  IsConvertToCorrectTimeAuthEnabled = 'is-convert-to-correct-time-auth-enabled',
  SplitContactsTab = 'split-contacts-tab',
  ExpandingFormSearch = 'platform-expanding-form-search',
  PayUnpaidBills = 'enable-pay-unpaid-bills',
  TimelineTrackerEnabled = 'platform-timeline-tracker-enabled',
  NetworkVendorSupport = 'platform-network-vendor-support',
  DisplayVendorAsFreeTextInstantBill = 'display-vendor-as-free-text-instant-bill',
  DisplayReceivingMethodsItem = 'display-platform-settings-receiving-methods',
  SwitchToZendeskChat = 'switch-to-zendesk-chat',
  CheckFeesPromotionEnabled = 'partnerships-free-checks',
  RequestADemo = 'request-a-demo',
  CustomCheckAddressEnabled = 'platform-custom-check-address-enabled',
  IsUnilateralEnrollmentEnabled = 'msn-unilatral-and-enrollment',
  UnilateralPaperCheckAddressFlow = 'unilateral-platform-papercheck-address-flow',
  CapOnePortalBackToL3 = 'capone-portal-back-to-l3',
  ShouldIndustryBePartOfLegalInfo = 'should-industry-be-part-of-legal-info',
  RobinhoodRefundFlow = 'partnerships-robinhood-refund-flow',
  IsInternationalSupportedForRecurringFlow = 'platform-international-payments-recurring',
  ARDashboard = 'ar-dashboard',
  PlatformFinancing = 'platform-financing',
  UseDeliveryDateSchedulingPreference = 'use-delivery-date-scheduling-preference',
  SelectDeliveryDateFeatureEnabled = 'select-delivery-date-feature-enabled',
  EnableUpsellInPlatformUI = 'enable-upsell-in-platform-ui',
  IsSpendManagementEnabled = 'is-spend-management-enabled',
  PlatformMultipleBillsUpload = 'platform-multiple-bills-upload',
  FetchPaymentActionsOnDashboard = 'fetch-payment-actions-on-dashboard',
  BillingFees = 'platform-billing-fees',
  PlatformSubscriptions = 'platform-subscriptions',
  SetAsDefaultPaymentMethodEnabled = 'set-a-default-payment-method-enabled',
  PlatformUserManagementAdjustmentsFiserv = 'platform-user-management-adjustments-fiserv',
  PlaidMicroDeposits = 'plaid-micro-deposits',
  PlaidMicroDepositsGetPaid = 'plaid-micro-deposits-get-paid',
  IsArProcessingFeeEnabled = 'is-ar-processing-fee-enabled',
}

/*
Please use PascalCase for the new enum names, according to the official typescript documentation page.
Link: https://www.typescriptlang.org/docs/handbook/enums.html
*/
