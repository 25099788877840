/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { merge } from 'lodash';
import { useRecoilValue } from 'recoil';
import { partnerGroupParser } from '@melio/partner-tools';
import { PartnerName } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { useAnonymousAppFeature } from '@/hooks/featureFlags';
import { PartnersConfiguration } from '@/partnersConfig';
import { PartnerConfig, TokenProvider } from '@/partnersConfig.types';
import { selectedPartner } from '@/store/app/app.model';
import { getOverrideParams } from '@/utils/getOverrideParams.utils';

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const usePartnerConfig = (partner?: PartnerName) => {
  const [isUsHolidaysChecksOn] = useFeature(FeatureFlags.USHolidaysChecks, false);
  const [vaultingTokenProvider] = useAnonymousAppFeature<TokenProvider>(FeatureFlags.VaultingTokenProvider, 'tbt', {
    shouldTrack: false,
  });

  const selectedPartnerRecoilValue = useRecoilValue(selectedPartner);

  const currentPartner = partner || (selectedPartnerRecoilValue as PartnerName);
  if (!currentPartner) {
    throw Error('Current partner could not be detected');
  }

  const { partnerGroup } = partnerGroupParser(currentPartner);
  const demoOverride = getOverrideParams(currentPartner);

  const partnerConfig = React.useMemo<PartnerConfig>(() => {
    const currentPartnerConfig = PartnersConfiguration[currentPartner!];

    const overrides: RecursivePartial<PartnerConfig> = {
      ...demoOverride,
      config: {
        settings: {
          ...(vaultingTokenProvider != null ? { tokenProvider: vaultingTokenProvider } : {}),
          eoyCheck: {
            enabled: isUsHolidaysChecksOn,
          },
          partnerDisplayName: currentPartnerConfig.displayName,
          partnerProductName: currentPartnerConfig.partnerProductName,
        },
      },
    };
    return { ...merge(currentPartnerConfig, overrides) };
  }, [currentPartner, vaultingTokenProvider, isUsHolidaysChecksOn]);

  useEffect(() => {
    if (partnerConfig?.config?.settings?.isEmbeddedExperience) {
      if (partnerConfig?.config?.settings?.embeddedExperienceInsideScroll) {
        document.body.classList.add('embedded-experience-inside-scroll');
      } else {
        document.body.classList.remove('embedded-experience-parent-scroll');
      }
    } else {
      document.body.classList.remove('embedded-experience-inside-scroll');
      document.body.classList.remove('embedded-experience-parent-scroll');
    }

    if (inIframe()) {
      document.body.classList.add('in-iframe');
    } else {
      document.body.classList.remove('in-iframe');
    }

    // HOTFIX: when moving from uploading a file to the Fiserv combined pay experience, this hook is
    // unnmounted, and the necessary css classes are removed. This is a temporary fix to ensure the
    // iFrame experience does not break. This will suffice until we can determine why the hook is unmounting.

    // return () => {
    //   document.body.classList.remove('embedded-experience-inside-scroll');
    //   document.body.classList.remove('embedded-experience-parent-scroll');
    //   document.body.classList.remove('in-iframe');
    // };
  }, [partnerConfig?.config?.settings?.isEmbeddedExperience]);

  return { partnerName: currentPartner!, partnerGroup, partnerConfig };
};
